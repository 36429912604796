





























































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { DxDataGrid, DxColumn } from "devextreme-vue/data-grid";
@Component({
  components: { DxDataGrid, DxColumn },
})
export default class DxDataGridDas extends Vue {
  @PropSync("DataSource", {
    required: true,
  })
  Datos!: any;
  @Prop({
    required: false,
  })
  onRowDblClick!: (...args: any[]) => void;
  @Prop({
    default: false,
    type: Boolean,
    required: false,
  })
  presupuesto!: boolean;
  @Prop({
    default: true,
    type: Boolean,
    required: false,
  })
  fecha!: boolean;
}
