import { store } from "@/store/store";
import { DashBoardGeneralDto } from "@/shared/dtos/dashboards/DashBoardGeneralDto";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { ConteoRegistrosDto } from "@/shared/dtos/dashboards/ConteoRegistrosDto";
import { DashboardDoughnutDto } from "@/shared/dtos/dashboards/DashboardDoughnutDto";
import { DashTablaProgreso } from "@/shared/dtos/dashboards/DashTablaProgreso";

@Module({
  namespaced: true,
  name: "DashBoardModule",
  store,
  dynamic: true,
})
class DashBoardModule extends VuexModule {
  public DashBoard: DashBoardGeneralDto = new DashBoardGeneralDto();
  public DashBoardConteo: ConteoRegistrosDto = new ConteoRegistrosDto();
  public DashboardDoughnut: DashboardDoughnutDto = new DashboardDoughnutDto();
  public DashTablaProgreso: DashTablaProgreso[][]= [];
  public DashTablaProgreso_vencen: DashTablaProgreso[][]= [];
  public DashboardTareasDeparamento: DashTablaProgreso[][]= [];
  public DashboardActuacionesDeparamento: DashTablaProgreso[][]= [];
  public DashboardActuacionesResponsable: DashTablaProgreso[][]= [];

  public DashboardTotalizadosTareasDepartamento: DashTablaProgreso[][]= [];
  public DashboardTotalizadosTareasAmbito: DashTablaProgreso[][]= [];
  public DashboardTotalizadosTareasObjetivo: DashTablaProgreso[][]= [];

  @Action({ commit: "onGetDashBoard" })
  public async getDashBoards() {
    return await ssmHttpService.get(API.DashBoard + "/DashBoard");
  }

  @Action({ commit: "onGetDashboardConteo" })
  public async getDashboardConteo() {
    return await ssmHttpService.get(API.DashBoard + "/DashboardConteo");
  }

  @Action({ commit: "onGetDashboardTareas" })
  public async getDashboardTareas() {
    return await ssmHttpService.get(API.DashBoard + "/DashboardTareas");
  }

  @Action({ commit: "onGetDashboardActuacionesDeparamento" })
  public async getDashboardActuacionesDeparamento() {
    return await ssmHttpService.get(API.DashBoard + "/MisActuacionesDepartamento");
  }

  @Action({ commit: "onGetDashboardActuacionesRelacionadas" })
  public async GetDashboardActuacionesRelacionadas() {
    return await ssmHttpService.get(API.DashBoard + "/MisActuacionesResponsable");
  }

  @Action({ commit: "onGetDashboardTareasDeparamento" })
  public async getDashboardTareasDeparamento() {
    return await ssmHttpService.get(API.DashBoard + "/DashboardTareasDeparamento");
  }

  @Action({ commit: "onGetDashboardDoughnut" })
  public async getDashboardDoughnut() {
    return await ssmHttpService.get(API.DashBoard + "/DashboardDoughnut");
  }

  @Action({ commit: "onGetDashboardTotalizadosTareasDepartamento" })
  public async getDashboardTotalizadosTareasDepartamento() {
    return await ssmHttpService.get(API.DashBoard + "/DashboardTotalizadosDeparamento");
  }

  @Action({ commit: "onGetDashboardTotalizadosTareasAmbito" })
  public async getDashboardTotalizadosTareasAmbito() {
    return await ssmHttpService.get(API.DashBoard + "/DashboardTotalizadosAmbito");
  }

  @Action({ commit: "onGetDashboardTotalizadosTareasObjetivo" })
  public async getDashboardTotalizadosTareasObjetivo() {
    return await ssmHttpService.get(API.DashBoard + "/DashboardTotalizadosObjetivo");
  }

  @Action({ commit: "onGetDashboardTareasVencen" })
  public async getDashboardTareasVencen() {
    return await ssmHttpService.get(API.DashBoard + "/DashboardTareas_fecha_fin");
  }

  @Mutation
  public onGetDashboardTotalizadosTareasAmbito(res: DashTablaProgreso[][]) {
    this.DashboardTotalizadosTareasAmbito = res;
  }

  @Mutation
  public onGetDashboardTotalizadosTareasObjetivo(res: DashTablaProgreso[][]) {
    this.DashboardTotalizadosTareasObjetivo = res;
  }

  @Mutation
  public onGetDashboardTotalizadosTareasDepartamento(res: DashTablaProgreso[][]) {
    this.DashboardTotalizadosTareasDepartamento = res;
  }


  @Mutation
  public onGetDashboardConteo(res: ConteoRegistrosDto) {
    this.DashBoardConteo = res;
  }
  
  @Mutation
  public onGetDashBoard(res: DashBoardGeneralDto) {
    this.DashBoard = res;
  }

  @Mutation
  public onGetDashboardTareasDeparamento(res: DashTablaProgreso[][]) {
    this.DashboardTareasDeparamento = res;
  }

  @Mutation
  public onGetDashboardDoughnut(res: DashboardDoughnutDto) {
    this.DashboardDoughnut = res;
  }

    
  @Mutation
  public onGetDashboardActuacionesRelacionadas(res: DashTablaProgreso[][]) {
    this.DashboardActuacionesResponsable = res;
  }

  @Mutation
  public onGetDashboardActuacionesDeparamento(res: DashTablaProgreso[][]) {
    this.DashboardActuacionesDeparamento = res;
  }
  @Mutation
  public onGetDashboardTareas(res: DashTablaProgreso[][]) {
    this.DashTablaProgreso = res;
  }
  @Mutation
  public onGetDashboardTareasVencen(res: DashTablaProgreso[][]) {
    this.DashTablaProgreso_vencen = res;
  }
}
export default getModule(DashBoardModule);
