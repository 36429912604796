



















































































































































































































































































































































































































































import DashBoardModule from "@/store/modules/DashBoard-module";
import { Component, Vue } from "vue-property-decorator";
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxCommonAxisSettings,
  DxGrid,
  DxExport,
  DxLegend,
  DxMargin,
  DxTooltip,
  DxLabel,
  DxFormat,
  DxTitle,
  DxSubtitle,
} from "devextreme-vue/chart";

import DxPieChart, { DxConnector } from "devextreme-vue/pie-chart";

import { DxDataGrid, DxColumn } from "devextreme-vue/data-grid";

import { RouterNames } from "@/router/routernames";
import DxDataGridDas from "./DxDataGridDas.vue";
import { UtilsString } from "@/utils/utils-string";
import actuacionModule from "@/store/modules/actuacion-module";
import objetivoModule from "@/store/modules/objetivo-module";
@Component({
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxCommonAxisSettings,
    DxGrid,
    DxExport,
    DxLegend,
    DxMargin,
    DxTooltip,
    DxLabel,
    DxPieChart,
    DxConnector,
    DxFormat,
    DxDataGrid,
    DxColumn,
    DxTitle,
    DxSubtitle,
    DxDataGridDas,
  },
})
export default class DashGeneral extends Vue {
  public tab = 0;

  public tabs: any[] = [
    { text: "Todas", value: 0 },
    { text: "Este Mes", value: 1 },
    { text: "En 7 días", value: 2 },
    { text: "Hoy", value: 3 },
  ];
  created() {
    DashBoardModule.getDashBoards();
    DashBoardModule.getDashboardConteo();
    DashBoardModule.getDashboardDoughnut();
    DashBoardModule.getDashboardTareas();
    DashBoardModule.getDashboardTareasDeparamento();
    DashBoardModule.getDashboardActuacionesDeparamento();
    DashBoardModule.GetDashboardActuacionesRelacionadas();
    DashBoardModule.getDashboardTotalizadosTareasDepartamento();
    DashBoardModule.getDashboardTotalizadosTareasAmbito();
    DashBoardModule.getDashboardTotalizadosTareasObjetivo();
    DashBoardModule.getDashboardTareasVencen();
    actuacionModule.getactuaciones();
    objetivoModule.getobjetivos();
  }
  get donut_objetivos_kpis() {
    var estados: any[] = [
      { text: "Pendientes de iniciar", val: 0 },
      { text: "En curso", val: 0 },
      { text: "Finalizadas", val: 0 },
    ];

    for (let index = 0; index < objetivoModule.objetivos.length; index++) {
      const element = objetivoModule.objetivos[index];
      if (element.kpis_progreso >= 99) {
        estados[2].val = estados[2].val + 1;
      } else if (element.kpis_progreso == 0) {
        estados[0].val = estados[0].val + 1;
      } else {
        estados[1].val = estados[1].val + 1;
      }
    }

    return estados;
  }
  get donut_objetivos_tareas() {
    var estados: any[] = [
      { text: "Pendientes de iniciar", val: 0 },
      { text: "En curso", val: 0 },
      { text: "Finalizadas", val: 0 },
    ];

    for (let index = 0; index < objetivoModule.objetivos.length; index++) {
      const element = objetivoModule.objetivos[index];
      if (element.progreso >= 99) {
        estados[2].val = estados[2].val + 1;
      } else if (element.progreso == 0) {
        estados[0].val = estados[0].val + 1;
      } else {
        estados[1].val = estados[1].val + 1;
      }
    }

    return estados;
  }
  get donut_actuaciones_kpis() {
    var estados: any[] = [
      { text: "Pendientes de iniciar", val: 0 },
      { text: "En curso", val: 0 },
      { text: "Finalizadas", val: 0 },
    ];

    for (let index = 0; index < actuacionModule.actuaciones.length; index++) {
      const element = actuacionModule.actuaciones[index];
      if (element.progreso_kpis >= 99) {
        estados[2].val = estados[2].val + 1;
      } else if (element.progreso_kpis == 0) {
        estados[0].val = estados[0].val + 1;
      } else {
        estados[1].val = estados[1].val + 1;
      }
    }

    return estados;
  }

  get donut_actuaciones_tareas() {
    var estados: any[] = [
      { text: "Pendientes de iniciar", val: 0 },
      { text: "En curso", val: 0 },
      { text: "Finalizadas", val: 0 },
    ];

    for (let index = 0; index < actuacionModule.actuaciones.length; index++) {
      const element = actuacionModule.actuaciones[index];
      if (element.ejecucion_sobre_total >= 99) {
        estados[2].val = estados[2].val + 1;
      } else if (element.ejecucion_sobre_total == 0) {
        estados[0].val = estados[0].val + 1;
      } else {
        estados[1].val = estados[1].val + 1;
      }
    }

    return estados;
  }

  get dash_totalizadosAmbito() {
    return DashBoardModule.DashboardTotalizadosTareasAmbito;
  }
  get dash_totalizadosObjetivo() {
    return DashBoardModule.DashboardTotalizadosTareasObjetivo;
  }
  get dash_totalizadosDep() {
    return DashBoardModule.DashboardTotalizadosTareasDepartamento;
  }

  get dash_general() {
    return DashBoardModule.DashBoard.graf_general;
  }

  get dash_general_conteo() {
    return DashBoardModule.DashBoardConteo;
  }

  get dash_objetivos_smart() {
    return DashBoardModule.DashboardDoughnut.objetivos_smart;
  }

  get dash_actuaciones_ambitos_smart() {
    return DashBoardModule.DashboardDoughnut.actuaciones_ambitos_smart;
  }
  get dash_actuaciones_tipos_smart() {
    return DashBoardModule.DashboardDoughnut.actuaciones_tipos_smart;
  }
  get dash_tareas_usuario() {
    return DashBoardModule.DashTablaProgreso;
  }
  get dash_tareas_vencen_usuario() {
    return DashBoardModule.DashTablaProgreso_vencen;
  }

  get dash_tareas_departamento() {
    return DashBoardModule.DashboardTareasDeparamento;
  }
  //dash_actuaciones_usuario dash_actuaciones_departamento
  get dash_actuaciones_usuario() {
    return DashBoardModule.DashboardActuacionesResponsable;
  }

  get dash_actuaciones_departamento() {
    return DashBoardModule.DashboardActuacionesDeparamento;
  }

  ClickRouterTableId(id: string) {
    this.$router.push({ name: RouterNames.table, params: { id: id } });
  }

  onRowDblClickTareas(e: any) {
    this.$router.push({
      name: RouterNames.tareasFormulario,
      params: { id: e.data.id },
    });
  }

  architectureSources = [
    { value: "actuaciones", name: "Actuaciones" },
    { value: "tareas", name: "Tareas" },
  ];
}
