var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxDataGrid',{attrs:{"data-source":_vm.Datos,"remote-operations":false,"allow-column-reordering":true,"row-alternation-enabled":true,"show-borders":true,"width":'100%',"onRowDblClick":_vm.onRowDblClick},scopedSlots:_vm._u([{key:"cellTemplate",fn:function(ref){
var data = ref.data;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('progress',_vm._g(_vm._b({style:('width: 80px; background-color:rgb(0,' +
              data.value * 100 +
              ', 0);border-radius: 50px; height: 6px;'),attrs:{"max":"100"},domProps:{"value":data.value * 100}},'progress',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(Number((data.value * 100).toFixed(2)))+" %")])])]}}])},[_c('DxColumn',{attrs:{"data-field":"nombre","caption":"Nombre","data-type":"string"}}),_c('DxColumn',{attrs:{"data-field":"responsable","caption":"Responsable","data-type":"string"}}),(_vm.fecha)?_c('DxColumn',{attrs:{"data-field":"fecha","caption":"Fecha","data-type":"date"}}):_vm._e(),(_vm.presupuesto)?_c('DxColumn',{attrs:{"data-field":"presupuesto_ejecutado","caption":"Pres. Eje.","data-type":"number","type":"currency","precision":"2","format":",###,###,##0.00 €","alignment":"right"}}):_vm._e(),_c('DxColumn',{attrs:{"data-field":"progreso","caption":"Progreso","data-type":"number","format":"percent","alignment":"right","cell-template":"cellTemplate"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }