import { BaseDto } from "@/shared/dtos/base-dto";

export class DashBoardGeneralDto extends BaseDto {

  public graf_incidencias_asignadas!: [];
  public graf_tareas_asignadas!: [];
  public graf_proyectos_asignadas!:[];

  public actuaciones_fecha_inicio!: [];
  public actuaciones_empiezan_esta_semana!: [];
  public actuaciones_terminan_esta_semana!: [];

  public graf_general!:[];
}
